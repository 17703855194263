import { Fade, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PostHogFeature } from 'posthog-js/react'
import { FEATURE_FLAGS } from '~/legacy/utils/featureFlags'
import {
  BulkImportCsvReader,
  Loading,
  SampleBulkCsvLink,
  SimpleAutocomplete,
  UploadIcon,
} from '~/legacy/components'
import { BuildingIcon, PlusIcon } from '~/legacy/components/svgs'
import { addSurveyListingAndBuilding } from '~/legacy/store/actions/viewSurvey'
import { useApiHelper } from '~/legacy/utils/hooks'
import FileExtractorInput from './FileExtractorInput'
import {
  BULK_IMPORT_HELPERS,
} from '~/legacy/utils'

const useStyles = makeStyles((theme) => ({
  ctaContainer: {
    color: '#666666',
    marginLeft: '100px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '60%',
  },
  icon: {
    position: 'absolute',
    color: '#666666',
  },
  iconContainer: {
    height: '48px',
    width: '48px',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: (props) => (props.editing ? 'default' : 'pointer'),
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '64px',
  },
  loadingSpinner: {
    marginLeft: '16px',
  },
  root: {
    height: '81px',
    marginTop: '-1px',
    width: '100%',
    border: '1px dotted #e0e0e0',
    borderTop: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  roundedCorner: {
    borderRadius: '1px 1px 4px 4px',
  },
  textContainer: {
    display: 'flex',
    cursor: 'pointer',
    paddingLeft: '16px',
  },
  csvTextContainer: {
    justifyContent: 'center',
  },
  plainTextContainer: {
    alignItems: 'center',
  },
  justAddedSurveyListingRowInitial: {
    background: 'rgba(42, 150, 110, 0.1)',
  },
  justAddedSurveyListingRowTransition: {
    background: 'rgba(255, 255, 255, 1)',
    transition: theme.transitions.create(['background'], {
      duration: '2s',
    }),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const INPUT_TEXT_SPACE = 'Quick add a new space'
const INPUT_TEXT_BUILDING = 'Quick add a new building'

export default function QuickEntry({
  surveyListings,
  collapsedBuildings,
  setCollapsedBuildings,
  surveyId,
  isUserInBuildout = false,
  setBackdropOpen,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen,
  setUploadedCsvFile = () => {},
}) {
  const INPUT_TEXT = isUserInBuildout ? INPUT_TEXT_BUILDING : INPUT_TEXT_SPACE
  const apiHelper = useApiHelper()
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({
    text: '',
    parts: {},
  })
  const classes = useStyles({ editing })

  useEffect(() => {
    if (selectedAddress.text) {
      apiHelper
        .quickEntryCreateListing({
          addressParts: selectedAddress.parts,
          surveyId,
        })
        .then((result) => {
          setSelectedAddress({
            text: '',
            parts: {},
          })
          setEditing(false)
          const resultData = result ? result.data || {} : {}
          const newSurveyBuilding = resultData.surveyBuilding || {}
          dispatch(
            addSurveyListingAndBuilding(
              resultData.surveyListing || null,
              newSurveyBuilding
            )
          )
          setCollapsedBuildings({
            ...collapsedBuildings,
            [resultData.buildingId]: false,
          })

          let scrollToElementId
          if (resultData.surveyListing) {
            scrollToElementId = `survey-listing-${resultData.surveyListing.id}`
          } else {
            scrollToElementId = `survey-building-${resultData.buildingId}`
          }
          const rowElementToScrollTo =
            document.getElementById(scrollToElementId)
          if (rowElementToScrollTo) {
            rowElementToScrollTo.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
            rowElementToScrollTo.classList.add(
              classes.justAddedSurveyListingRowInitial
            )
            window.setTimeout(() => {
              rowElementToScrollTo.classList.add(
                classes.justAddedSurveyListingRowTransition
              )

              window.setTimeout(() => {
                rowElementToScrollTo.classList.remove(
                  classes.justAddedSurveyListingRowInitial,
                  classes.justAddedSurveyListingRowTransition
                )
              }, 2000) // the length of the background color transition
            }, 50)
          }
        })
    }
  }, [selectedAddress])

  const linkRef = React.useRef(null)
  const noSurveyListings = surveyListings.length === 0

  return (
    <>
      <div className={classes.root}>
        <div className={classes.ctaContainer}>
          <div className={classes.iconContainer}>
            <Fade in={editing}>
              <BuildingIcon className={classes.icon} />
            </Fade>
            <Fade in={!editing}>
              <PlusIcon
                className={classes.icon}
                onClick={() => !selectedAddress.text && setEditing(!editing)}
              />
            </Fade>
          </div>
          <Fade in={editing} mountOnEnter unmountOnExit>
            <div
              className={classes.input}
              onClick={(event) => event.stopPropagation()}
            >
              <SimpleAutocomplete
                onBlur={() => setEditing(false)}
                autoFocusAddressInput
                onChange={(newAddress, newAddressParts) => {
                  setSelectedAddress({
                    text: newAddress,
                    parts: newAddressParts,
                  })
                  setEditing(false)
                }}
                placeholder={INPUT_TEXT}
              />
            </div>
          </Fade>
          <Fade in={!editing}>
            <div
              className={clsx(
                classes.textContainer,
                classes.plainTextContainer
              )}
              onClick={() => !selectedAddress.text && setEditing(!editing)}
            >
              <Typography variant="body1">
                {selectedAddress.text ? selectedAddress.text : INPUT_TEXT}
              </Typography>
              <Loading
                className={classes.loadingSpinner}
                isLoading={!!selectedAddress.text}
                size={24}
              />
            </div>
          </Fade>
        </div>
      </div>

      {/* Button for the CSV reader... only works with link labels for whatever reason */}
      {!noSurveyListings && (
        <div className={clsx(classes.root, classes.roundedCorner)}>
          <div className={classes.ctaContainer}>
            <Fade in={!editing}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={classes.iconContainer}>
                  <UploadIcon
                    className={classes.icon}
                    onClick={() => {
                      linkRef.current.click()
                    }}
                  />
                </div>
                <div
                  className={clsx(
                    classes.column,
                    classes.textContainer,
                    classes.csvTextContainer
                  )}
                >
                  <Typography
                    className={classes.emptyContainerText}
                    variant="body1"
                  >
                    Easily add multiple
                    {isUserInBuildout ? ' buildings ' : ' spaces '}
                    at once using a CSV file.
                  </Typography>
                  <Typography variant="body1">
                    Download the 
                    {' '}
                    <SampleBulkCsvLink />
                  </Typography>
                </div>
                <BulkImportCsvReader
                  linkRef={linkRef}
                  setBackdropOpen={setBackdropOpen}
                  setUploadedCsvFile={setUploadedCsvFile}
                  setLoadedListingsFromCsv={setLoadedListingsFromCsv}
                  setRawCsvHeaders={setRawCsvHeaders}
                  setBulkImportFieldMatchModalOpen={
                    setBulkImportFieldMatchModalOpen
                  }
                />
              </div>
            </Fade>
          </div>
        </div>
      )}

      <PostHogFeature flag={FEATURE_FLAGS.INGEST_PDF} match>
        <div className={clsx(classes.root, classes.roundedCorner)}>
          <div className={classes.ctaContainer}>
            <FileExtractorInput
              onExtracted={(results) => {
                console.log(results);
                BULK_IMPORT_HELPERS.onCsvFileLoaded(
                  results,
                  setLoadedListingsFromCsv,
                  setRawCsvHeaders,
                  setBulkImportFieldMatchModalOpen
                )
              }}
              setParentLoading={setBackdropOpen}
              isUserInBuildout={isUserInBuildout}
            />
          </div>
        </div>
      </PostHogFeature>
    </>
  )
}
