import { format, parse } from 'date-fns';
import {
  ListIcon,
  NumberIcon,
  SingleLineTextIcon,
  AddressIcon,
  CurrencyIcon,
  SizeIcon,
  CalendarIcon,
  SpaceIcon,
  BuildingIcon,
  SqftIcon,
  PricePerSqftIcon,
  DateAvailableIcon,
  LeaseTermsIcon,
} from '~/legacy/components/svgs';
import { formatDateAvailableString, formatSize } from './listingHelpers';
import { formatMoney, numberWithCommas } from './numberFormaters';

import { caseInsensitiveCompare } from './miscUtils';

const LIST_SEPARATOR = ',';

const parseSqftPrice = (priceString = '') => {
  // match floats or single digits for money
  // eslint-disable-next-line
  const regex = /\d+(\.\d{2})?/g;
  const matches = priceString.match(regex);
  if (matches) {
    return matches.pop();
  }
  return null;
};

const prepareList = (listValue) => {
  if (listValue) {
    // trim, filter, and dedupe
    const cleanedListValue = listValue
      .map((item) => item.trim())
      .filter((e) => !!e);
    return Array.from(new Set(cleanedListValue));
  }
  return listValue;
};

const splitListString = (stringValue) => {
  return stringValue ? stringValue.split(LIST_SEPARATOR) : stringValue;
};

const joinListString = (arrayValue) => {
  return arrayValue ? arrayValue.join(LIST_SEPARATOR) : arrayValue;
};

const prepareListString = (stringValue) => {
  return stringValue ? prepareList(splitListString(stringValue)) : stringValue;
};

const prepareDate = (valueToPrepare, formatString = 'yyyy-MM-dd') => {
  let noTimeRawValue = valueToPrepare;
  if (valueToPrepare) {
    if (typeof valueToPrepare === 'string') {
      noTimeRawValue = parse(valueToPrepare, formatString, new Date());
    } else {
      noTimeRawValue = new Date(valueToPrepare.toDateString());
    }
  }

  return noTimeRawValue;
};

const prepareDateForAPISave = (valueToPrepare) => {
  const preparedValue = prepareDate(valueToPrepare);
  return preparedValue ? format(preparedValue, 'yyyy-MM-dd') : preparedValue;
};

// Validate the provided address
const validateAddress = (newAddress, rawListingData, csvHeaderIndexLookup) => {
  const newGoogleAddress = newAddress ? newAddress.GOOGLE_ADDRESS : null;
  const googleAutocompleteResults = newAddress
    ? newAddress.GOOGLE_AUTOCOMPLETE_RESULTS
    : null;
  const csvValue = {
    address: rawListingData[csvHeaderIndexLookup.address],
    city: rawListingData[csvHeaderIndexLookup.city],
    state: rawListingData[csvHeaderIndexLookup.state],
    zipcode: rawListingData[csvHeaderIndexLookup.zipcode],
  };

  // If we've successfully got a new address from Google api, then use it.
  if (newGoogleAddress) {
    return {
      value: {
        GOOGLE_ADDRESS: newGoogleAddress,
        GOOGLE_AUTOCOMPLETE_RESULTS: googleAutocompleteResults,
      },
      csvValue,
      error: null,
    };
  }
  // Else, we just use whatever the user had in the CSV
  return { value: {}, csvValue, error: "This isn't a valid address." };
};

const validateNumeric = (value) => {
  // TODO: use a proper library like Globalize to handle all of the diff international number formats. It's a bit of a set up process tho
  // Get the value, if its a string, then trim and try to parse out the SQFT bit
  let extractedValue = value;
  let extractedFloat = value;
  if (typeof value === 'string') {
    extractedValue = value.trim();
    extractedFloat = parseSqftPrice(extractedValue.replaceAll(',', ''));
  }

  // If we've got an empty string, treat field as empty
  if (
    extractedValue === '' ||
    extractedValue === null ||
    extractedValue === undefined
  )
    return { value: null, csvValue: value, error: null };

  // Try to parse the extracted "float" as a float. If its not a number, error
  const parsedFloat = parseFloat(extractedFloat);
  if (isNaN(parsedFloat))
    return {
      value: null,
      csvValue: value,
      error: 'This field expects numbers.',
    };

  // We've got a numeric!
  return { value: parsedFloat, csvValue: value, error: null };
};

const validateList = (value) => {
  if (value === undefined || value === null)
    return { value: null, csvValue: value, error: null };

  // Make the value a string, and then try to parse it as a semicolon separated list
  const valueAsString = String(value);
  if (valueAsString) {
    const splitList = prepareListString(valueAsString);
    return { value: splitList, csvValue: value, error: null };
  }
  return { value: null, csvValue: value, error: null };
};

const validateDate = (value) => {
  if (value === undefined || value === null)
    return { value: null, csvValue: value, error: null };

  if (typeof value === 'string') {
    const stringValue = value.trim();
    // Empty string is just empty
    if (stringValue === '')
      return { value: null, csvValue: value, error: null };
    // Parse the string as a date, and if its a valid date, we got a good value
    // JS date are the devil
    let newStringValue = stringValue;
    if (newStringValue.includes('-')) {
      newStringValue = newStringValue.replaceAll('-', '/');
    }
    const newDate = new Date(Date.parse(newStringValue));
    if (newDate instanceof Date && !isNaN(newDate))
      return { value: newDate, csvValue: value, error: null };
  }

  // If its defined but not a string or a valid date, we've got a problem
  return {
    value: null,
    csvValue: value,
    error: 'This field respects dates.',
  };
};

// The types of the fields, listing or building
const FIELD_TYPES = {
  SPACE: {
    id: 1,
    name: 'Space',
    icon: SpaceIcon,
  },
  BUILDING: {
    id: 2,
    name: 'Building',
    icon: BuildingIcon,
  },
};

// The fields we will allow the user to select from for their custom fields.
// Also in order for the picklist
const USER_SELECTABLE_FIELD_TYPES_ORDERED = [
  FIELD_TYPES.BUILDING,
  FIELD_TYPES.SPACE,
];

const defaultFormatter = (value) => value;
// The data types of our fields. Each column in the CSV will have one of these types.
const FIELD_DATA_TYPES = {
  STRING: {
    id: 1,
    icon: SingleLineTextIcon,
    name: 'Single Line Text',
    formatter: defaultFormatter,
    validator: (val) => ({ value: val, csvValue: val, error: null }),
    inputType: 'text',
  },
  ADDRESS: {
    id: 2,
    icon: AddressIcon,
    name: 'Address',
    formatter: defaultFormatter,
    validator: validateAddress,
    inputType: 'address',
  },
  NUMBER: {
    id: 3,
    icon: NumberIcon,
    name: 'Number',
    formatter: numberWithCommas,
    validator: validateNumeric,
    inputType: 'number',
  },
  LIST: {
    id: 4,
    icon: ListIcon,
    name: 'Multiselect',
    formatter: (value) => (value ? value.join(',') : null),
    validator: validateList,
    inputType: 'multi-select',
  },
  SIZE_SQFT: {
    id: 5,
    icon: SizeIcon,
    name: 'Size in sqft',
    formatter: formatSize,
    validator: validateNumeric,
    inputType: 'number',
  },
  CURRENCY_USD: {
    id: 6,
    icon: CurrencyIcon,
    name: 'Currency',
    formatter: formatMoney,
    validator: validateNumeric,
    inputType: 'number',
  },
  DATE: {
    id: 7,
    icon: CalendarIcon,
    name: 'Date',
    formatter: formatDateAvailableString,
    validator: validateDate,
    inputType: 'date',
  },
  MULTILINE_STRING: {
    id: 8,
    icon: SingleLineTextIcon,
    name: 'Multiline Text',
    formatter: defaultFormatter,
    validator: (val) => ({ value: val, csvValue: val, error: null }),
    inputType: 'multiline-text',
  },
}

const FIELD_DATA_TYPES_LOOKUP = Object.fromEntries(
  Object.values(FIELD_DATA_TYPES).map((fieldType) => [fieldType.id, fieldType])
);

export const getIconByDataTypeId = (id) => {
  const fieldData = Object.values(FIELD_DATA_TYPES).find(
    (value) => value.id === id
  );
  if (fieldData.icon) {
    return fieldData.icon;
  }
  return null;
};

export const getDataTypeNameByDataTypeId = (id) => {
  const fieldData = Object.values(FIELD_DATA_TYPES).find(
    (value) => value.id === id
  );
  if (fieldData.name) {
    return fieldData.name;
  }
  return null;
};

const NUMERIC_FIELD_DATA_TYPE_IDS = new Set([
  FIELD_DATA_TYPES.NUMBER.id,
  FIELD_DATA_TYPES.CURRENCY_USD.id,
  FIELD_DATA_TYPES.SIZE_SQFT.id,
]);

// The 4 CSV colum fields used for the composite address field
const ADDRESS_FIELD_PARTS = {
  ADDRESS: 'address',
  CITY: 'city',
  STATE: 'state',
  ZIPCODE: 'zipcode',
};

// The fields we will allow the user to select from for their custom fields.
// Also in order for the picklist
const USER_SELECTABLE_FIELD_DATA_TYPES_ORDERED = [
  FIELD_DATA_TYPES.NUMBER,
  FIELD_DATA_TYPES.STRING,
  FIELD_DATA_TYPES.MULTILINE_STRING,
  FIELD_DATA_TYPES.CURRENCY_USD,
  FIELD_DATA_TYPES.SIZE_SQFT,
  FIELD_DATA_TYPES.DATE,
  FIELD_DATA_TYPES.LIST,
]

// Our special fields defined individually
// displayName: Name displayed to the user
// matches: array of strings to match to the csv column headers, case insensitive
// fieldDataType: The data type of the field. String, number etc.
// icon: Icon for the field
// fieldType: The type of the field, building or space
// modelFieldName: The name of the field on the building/space model that matches this field
// reserved: Whether or not this field's metadat can be changed. These are Leaseup reserved/standard fields
const DEFAULT_PLACEHOLDER_STRING = 'Enter a value';
const FIELDS = {
  // reserved/standard fields
  SIZE: {
    fieldId: -5,
    displayName: 'SqFt Available',
    matches: ['SqFt Available', 'Total Available Space (SF)'],
    fieldDataType: FIELD_DATA_TYPES.SIZE_SQFT,
    icon: SqftIcon,
    fieldType: FIELD_TYPES.SPACE,
    reserved: true,
    modelFieldName: 'size',
  },
  PRICE: {
    fieldId: -4,
    displayName: 'Price / SqFt',
    matches: ['Rent/SF/Yr', 'Price / SqFt'],
    fieldDataType: FIELD_DATA_TYPES.CURRENCY_USD,
    icon: PricePerSqftIcon,
    fieldType: FIELD_TYPES.SPACE,
    reserved: true,
    modelFieldName: 'sqft_price',
  },
  DATE_AVAILABLE: {
    fieldId: -3,
    displayName: 'Date Available',
    matches: ['Date Available'],
    fieldDataType: FIELD_DATA_TYPES.DATE,
    icon: DateAvailableIcon,
    fieldType: FIELD_TYPES.SPACE,
    reserved: true,
    modelFieldName: 'date_available',
  },
  LEASE_TERM: {
    fieldId: -1,
    displayName: 'Lease Term',
    matches: ['Lease Term'],
    fieldDataType: FIELD_DATA_TYPES.STRING,
    icon: LeaseTermsIcon,
    fieldType: FIELD_TYPES.SPACE,
    reserved: true,
    modelFieldName: 'lease_term',
  },
  ADDRESS: {
    displayName: 'Address',
    csvString: ADDRESS_FIELD_PARTS.ADDRESS,
    fieldDataType: FIELD_DATA_TYPES.ADDRESS,
    index: -1,
    reserved: true,
    disableAllEditing: true,
    fieldType: FIELD_TYPES.BUILDING,
  },
  SPACE_NOTES: {
    fieldId: -7,
    displayName: 'Space Notes',
    matches: ['Space Notes', 'Listing Notes'],
    fieldDataType: FIELD_DATA_TYPES.MULTILINE_STRING,
    fieldType: FIELD_TYPES.SPACE,
    reserved: true,
    icon: SingleLineTextIcon,
    modelFieldName: 'description',
    placeholderText: 'Add Note',
  },

  // Suggested fields
  AMENITIES: {
    fieldId: -6,
    displayName: 'Amenities',
    matches: ['Amenities'],
    fieldDataType: FIELD_DATA_TYPES.LIST,
    fieldType: FIELD_TYPES.BUILDING,
    modelFieldName: 'amenities',
  },
  SPACE_NAME: {
    fieldId: -8,
    displayName: 'Space Name',
    matches: ['Space Name'],
    fieldDataType: FIELD_DATA_TYPES.STRING,
    fieldType: FIELD_TYPES.SPACE,
    modelFieldName: 'address2',
  },
  FLOORS: {
    fieldId: -2,
    displayName: 'Floors',
    matches: ['Floors'],
    fieldDataType: FIELD_DATA_TYPES.NUMBER,
    fieldType: FIELD_TYPES.BUILDING,
    modelFieldName: 'floors',
  },
  VACANCY_TYPE: {
    fieldId: -6,
    displayName: 'Vacancy Type',
    matches: ['Vacancy Type'],
    fieldDataType: FIELD_DATA_TYPES.STRING,
    fieldType: FIELD_TYPES.SPACE,
    modelFieldName: 'vacancy_type',
  },
  LEASE_TYPE: {
    fieldId: -2,
    displayName: 'Lease Type',
    matches: ['Lease Type'],
    fieldDataType: FIELD_DATA_TYPES.STRING,
    fieldType: FIELD_TYPES.SPACE,
    modelFieldName: 'lease_type',
  },
  PROPERTY_TYPE: {
    fieldId: -1,
    displayName: 'Property Type',
    matches: ['Property Type', 'PropertyType'],
    fieldDataType: FIELD_DATA_TYPES.STRING,
    fieldType: FIELD_TYPES.BUILDING,
    modelFieldName: 'property_type',
  },
  BUILDING_SIZE: {
    fieldId: -3,
    displayName: 'Building Size',
    matches: ['Building Size', 'BuildingSize', 'RBA'],
    fieldDataType: FIELD_DATA_TYPES.SIZE_SQFT,
    fieldType: FIELD_TYPES.BUILDING,
    modelFieldName: 'building_size',
  },
  PARKING_RATIO: {
    fieldId: -4,
    displayName: 'Parking Ratio',
    matches: ['Parking Ratio'],
    fieldDataType: FIELD_DATA_TYPES.NUMBER,
    fieldType: FIELD_TYPES.BUILDING,
    modelFieldName: 'parking_ratio',
  },
  BUILDING_DESCRIPTION: {
    fieldId: -5,
    displayName: 'Building Description',
    matches: [], // TODO: bulk import match?
    fieldDataType: FIELD_DATA_TYPES.MULTILINE_STRING,
    fieldType: FIELD_TYPES.BUILDING,
    modelFieldName: 'description',
  },
};

const BUILDING_FIELDS = Object.values(FIELDS).filter(
  (field) => field.fieldType === FIELD_TYPES.BUILDING
);
const SPACE_FIELDS = Object.values(FIELDS).filter(
  (field) => field.fieldType === FIELD_TYPES.SPACE
);

const FIELDS_LOOKUP = {
  'SqFt Available': FIELDS.SIZE,
  'Price / SqFt': FIELDS.PRICE,
  'Date Available': FIELDS.DATE_AVAILABLE,
  'Lease Term': FIELDS.LEASE_TERM,
  Address: FIELDS.ADDRESS,
  Amenities: FIELDS.AMENITIES,
  'Space Name': FIELDS.SPACE_NAME,
  Floors: FIELDS.FLOORS,
  'Vacancy Type': FIELDS.VACANCY_TYPE,
  'Lease Type': FIELDS.LEASE_TYPE,
  'Property Type': FIELDS.PROPERTY_TYPE,
  'Building Size': FIELDS.BUILDING_SIZE,
  'Parking Ratio': FIELDS.PARKING_RATIO,
  'Space Notes': FIELDS.SPACE_NOTES,
  'Building Description': FIELDS.BUILDING_DESCRIPTION,
};

// Array of our special fields. We will use these fields to suggest the type of
// the match.
const SUGGESTED_FIELDS = [
  FIELDS.AMENITIES,
  FIELDS.SPACE_NAME,
  FIELDS.FLOORS,
  FIELDS.VACANCY_TYPE,
  FIELDS.LEASE_TYPE,
  FIELDS.PROPERTY_TYPE,
  FIELDS.BUILDING_SIZE,
  FIELDS.PARKING_RATIO,
];

// Our standard fields across listings. They are reserved and their metadata cannot be changed
//   by the user.
const STANDARD_FIELDS = Object.values(FIELDS).filter(
  (f) => f.reserved && f.index !== FIELDS.ADDRESS.index
);

// Reserved leaseup fields. We control their type, display name, etc.
const RESERVED_FIELDS = [FIELDS.ADDRESS, ...STANDARD_FIELDS];

// The CSV fields that will be used to build the address
const ADDRESS_FIELDS = [
  {
    matches: ['Property Address', 'Address', 'Property'],
    newFieldName: ADDRESS_FIELD_PARTS.ADDRESS,
  },
  {
    matches: ['City'],
    newFieldName: ADDRESS_FIELD_PARTS.CITY,
  },
  {
    matches: ['State'],
    newFieldName: ADDRESS_FIELD_PARTS.STATE,
  },
  {
    matches: ['Zipcode'],
    newFieldName: ADDRESS_FIELD_PARTS.ZIPCODE,
  },
];

// Fields we will strip from the csv data since they are used for special composite fields
// ie: city, address, state, zipcode
const SPECIAL_FIELDS_TO_STRIP = ADDRESS_FIELDS.map(
  (addressField) => addressField.matches
).flat();

export const getDataTypeByDisplayName = (
  displayName,
  defaultDataType = FIELD_DATA_TYPES.STRING
) => {
  const fieldData = FIELDS_LOOKUP[displayName];
  return fieldData ? fieldData.fieldDataType.id : defaultDataType.id;
};

export const getPlaceholderTextByDisplayName = (displayName) => {
  const fieldData = FIELDS_LOOKUP[displayName];
  return fieldData
    ? fieldData.placeholderText || DEFAULT_PLACEHOLDER_STRING
    : DEFAULT_PLACEHOLDER_STRING;
};

export const getModelFieldNameByDisplayName = (displayName) => {
  const fieldData = FIELDS_LOOKUP[displayName];
  return fieldData ? fieldData.modelFieldName : null;
};

// Validate the raw value, signal if there is an error with the value
export const getValidatedFieldValue = (
  field,
  validatedListingData,
  rawListingData,
  csvHeaderIndexLookup
) => {
  const value = validatedListingData[field.index];
  const rawValue = value ? value.csvValue : null;

  if (field.fieldDataType.id === FIELD_DATA_TYPES.ADDRESS.id) {
    return validateAddress(
      value ? value.value : null,
      rawListingData,
      csvHeaderIndexLookup
    );
  }

  if (
    field.fieldDataType.id === FIELD_DATA_TYPES.STRING.id ||
    field.fieldDataType.id === FIELD_DATA_TYPES.MULTILINE_STRING.id
  ) {
    return { value: rawValue, csvValue: rawValue, error: null };
  }
  if (
    field.fieldDataType.id === FIELD_DATA_TYPES.NUMBER.id ||
    field.fieldDataType.id === FIELD_DATA_TYPES.SIZE_SQFT.id ||
    field.fieldDataType.id === FIELD_DATA_TYPES.CURRENCY_USD.id
  ) {
    return validateNumeric(rawValue);
  }

  if (field.fieldDataType.id === FIELD_DATA_TYPES.LIST.id) {
    return validateList(rawValue);
  }

  if (field.fieldDataType.id === FIELD_DATA_TYPES.DATE.id) {
    const result = validateDate(rawValue);
    return result;
  }

  return {
    value: rawValue,
    csvValue: rawValue,
    error: "This isn't a valid value.",
  };
};

// Match a CSV field with one of our standard fields
const matchMappableField = (
  field,
  csvField,
  fieldsWithMatches,
  setFieldsWithMatches,
  mappedStandardFields,
  setMappedStandardFields,
  mappableFieldIndex
) => {
  const csvFieldName = csvField.csvString;
  // Match the csv field with the mappable field by overwriting the display name and type
  // First, unset any previous matches to this special field
  const newfieldsWithMatches = Object.fromEntries(
    Object.values(fieldsWithMatches).map((fieldWithMatches) => {
      return [
        fieldWithMatches.index,
        caseInsensitiveCompare(field.displayName, fieldWithMatches.displayName)
          ? {
              ...fieldWithMatches,
              displayName: fieldWithMatches.csvString,
              match: null,
              icon: null,
              reserved: false,
            }
          : fieldWithMatches,
      ];
    })
  );

  // Replace the match
  newfieldsWithMatches[csvField.index] = {
    ...newfieldsWithMatches[csvField.index],
    csvString: csvFieldName,
    displayName: field.displayName,
    fieldDataType: field.fieldDataType,
    fieldType: field.fieldType,
    reserved: field.reserved,
    match: csvField.index,
  };

  // Update our mappable fields with the match to the csv field. We unset any previous matches to this field, as there
  //   can only be one match to a csv column.
  const newMappedStandardFields = [...mappedStandardFields].map(
    (mappedStandardField) =>
      mappedStandardField.match === csvField.index
        ? { ...mappedStandardField, match: null }
        : mappedStandardField
  );
  newMappedStandardFields[mappableFieldIndex] = {
    ...field,
    match: csvField.index,
  };

  setMappedStandardFields(newMappedStandardFields);
  setFieldsWithMatches(newfieldsWithMatches);
};

// Handler for after the csv data has been loaded
const onCsvFileLoaded = (
  results,
  setLoadedListingsFromCsv,
  setRawCsvHeaders,
  setBulkImportFieldMatchModalOpen
) => {
  setLoadedListingsFromCsv(results);
  if (results && results.length) {
    setRawCsvHeaders(Object.keys(results[0]));
  }
  setBulkImportFieldMatchModalOpen(true);
};

// Parser options for csv uploader
const CSV_UPLOAD_PARSER_OPTIONS = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (headerIn) => {
    const strippedHeader = headerIn ? headerIn.trim() : headerIn;
    const matchedField = ADDRESS_FIELDS.find((field) =>
      field.matches.find(
        (fMatch) =>
          fMatch.localeCompare(strippedHeader, undefined, {
            sensitivity: 'base',
          }) === 0
      )
    );
    return matchedField ? matchedField.newFieldName : strippedHeader;
  },
  complete: () => {},
};

export const getNumberValueForDisplay = (value, dataType) => {
  let displayValue = value;
  if (value !== null && value !== undefined) {
    displayValue = Number(displayValue);
    if (dataType === FIELD_DATA_TYPES.CURRENCY_USD.id) {
      displayValue = displayValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      displayValue = displayValue.toLocaleString();
    }

    if (dataType === FIELD_DATA_TYPES.SIZE_SQFT.id) {
      displayValue = `${displayValue} sqft`;
    } else if (dataType === FIELD_DATA_TYPES.CURRENCY_USD.id) {
      displayValue = `$${displayValue}`;
    }
  }

  return displayValue;
};

const getFieldDataTypeById = (fieldDataTypeId) => {
  return Object.values(FIELD_DATA_TYPES).find(
    (fieldDataType) => fieldDataType.id === fieldDataTypeId
  );
};

export const BULK_IMPORT_CONSTANTS = {
  FIELD_TYPES,
  USER_SELECTABLE_FIELD_TYPES_ORDERED,
  FIELD_DATA_TYPES,
  FIELD_DATA_TYPES_LOOKUP,
  FIELDS,
  BUILDING_FIELDS,
  SPACE_FIELDS,
  ADDRESS_FIELD_PARTS,
  USER_SELECTABLE_FIELD_DATA_TYPES_ORDERED,
  SUGGESTED_FIELDS,
  STANDARD_FIELDS,
  RESERVED_FIELDS,
  SPECIAL_FIELDS_TO_STRIP,
  ADDRESS_FIELDS,
  CSV_UPLOAD_PARSER_OPTIONS,
  NUMERIC_FIELD_DATA_TYPE_IDS,
  DEFAULT_PLACEHOLDER_STRING,
};

export const BULK_IMPORT_VALIDATORS = {
  validateAddress,
  validateNumeric,
  validateList,
  validateDate,
};

export const BULK_IMPORT_HELPERS = {
  matchMappableField,
  onCsvFileLoaded,
  getNumberValueForDisplay,
  prepareList,
  prepareListString,
  splitListString,
  prepareDate,
  getFieldDataTypeById,
  joinListString,
  prepareDateForAPISave,
};
