import { format, parse, isValid } from 'date-fns'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'
import {
  BULK_IMPORT_CONSTANTS,
  formatMoney,
  numberWithCommas,
  getNumberValueForDisplay,
} from '~/legacy/utils'
import {
  DESCRIPTION_LISTING_DETAILS_VIEW,
  KEY_VALUE_LISTING_DETAILS_VIEW,
} from '~/legacy/consts'
import { AmenitiesIcon } from '~/legacy/components'

export function generateListingDetailsProperty(
  propertyType,
  features,
  buildingSize,
  floors,
  parkingRatio
) {
  const hasDetails =
    propertyType || features || buildingSize || floors || parkingRatio
  let details = []
  if (hasDetails) {
    details = [
      {
        name: 'Property Type',
        value: propertyType,
      },
      {
        name: 'Building Size',
        value: numberWithCommas(buildingSize),
      },
      {
        name: 'Floors',
        value: floors,
      },
      {
        name: 'Space Features',
        value: features,
      },
      {
        name: 'Parking Ratio',
        value: parkingRatio,
      },
    ]
  }
  return {
    title: 'Property Type',
    details,
    type: KEY_VALUE_LISTING_DETAILS_VIEW,
  }
}

export function generateListingDetailsLease(
  vacancyType,
  leaseType,
  dateAvailable,
  amenities
) {
  const hasDetails =
    vacancyType || leaseType || dateAvailable || amenities.length
  let details = []
  if (hasDetails) {
    details = [
      {
        name: 'Vacancy Type',
        value: vacancyType,
      },
      {
        name: 'Lease Type',
        value: leaseType,
      },
      {
        name: 'Building Amenities',
        value: amenities,
      },
    ]
  }
  return {
    title: 'Lease Details',
    details,
    type: KEY_VALUE_LISTING_DETAILS_VIEW,
  }
}

export function generateListingDetailsDescription(description) {
  if (!description) {
    return null
  }

  return {
    title: 'Description',
    details: [
      {
        name: 'Description',
        value: description,
      },
    ],
    type: DESCRIPTION_LISTING_DETAILS_VIEW,
  }
}

export function getYearlySqFtPrice(listing) {
  let price = null
  if (listing && typeof listing === 'object') {
    price = listing.sqft_price || listing.price
    price = parseFloat(price).toFixed(2)
  }

  return price
}

/**
 * The Best Building, Suite 101
 * or
 * The Best Building
 * or
 * Suire 101
 */
export function getListingCardNameByParam(
  buildingName,
  listingSpaceName = null
  // isUserInBuildout = false
) {
  const pieces = []
  if (buildingName) {
    pieces.push(buildingName)
  }
  if (listingSpaceName) {
    pieces.push(listingSpaceName)
  }
  if (pieces.length === 0) {
    // pieces.push(isUserInBuildout ? 'Availability' : 'Space Available')
  }
  return pieces.join(', ')
}
export function getListingCardName(listing) {
  const buildingName = listing && listing.building ? listing.building.name : ''
  const listingSpaceName = listing ? listing.address2 : ''
  return getListingCardNameByParam(buildingName, listingSpaceName)
}

export function getBuildingCardName(building) {
  return building ? building.name : ''
  // return buildingName || 'Availability';
}

// These functions can be combined to get a full building address, here are some examples of the
//   same address but the first has a building name specified.
// 1. Prudential Center
//    800 Boylston Street Suite 200
//    Boston, Massachusetts 02199
//
// 2. 800 Boylston Street
//    Boston, Massachusetts 02199
export function getBuildingPrimaryNameByParam(buildingName, buildingAddress) {
  /* "Prudential Center" or "800 Boylston Street" */
  return buildingName || buildingAddress || ''
}
export function getBuildingPrimaryName(building) {
  return getBuildingPrimaryNameByParam(building.name, building.address)
}

export function getBuildingSecondaryNameByParam(
  buildingName,
  buildingAddress,
  buildingAddress2
) {
  /* "800 Boylston Street Suite 200" or null */
  if (buildingName) {
    return (
      (buildingAddress || '') + (buildingAddress2 ? ` ${buildingAddress2}` : '')
    )
  }
  return null
}
export function getBuildingSecondaryName(building) {
  return getBuildingSecondaryNameByParam(
    building.name,
    building.address,
    building.address2
  )
}

export function getBuildingTertiaryNameByParam(
  buildingCity,
  buildingState,
  buildingZipcode
) {
  /* "Boston, Massachusetts 02199" */
  return `${
    buildingCity ? `${buildingCity}, ` : ''
  }${buildingState} ${buildingZipcode}`
}
export function getBuildingTertiaryName(building) {
  return getBuildingTertiaryNameByParam(
    building.city,
    building.state,
    building.zipcode
  )
}

// Generally for these functions we are preferring the building name over the
//   building address if it's present. We are also including the listing add 2 if
//   it is present. These three functions can be combined to each produce a line of the
//   following complete addresses as examples.
//   1. Prudential Center Floor 7
//      800 Boylston Street Suite 200
//      Boston, Massachusetts 02199
//   2. 800 Boylston Street Floor 7
//      Boston, Massachusetts 02199
export function getListingPrimaryName(listing, includeComma = false) {
  /*
  "Prudential Center Foor 7" or "Prudential Center"
  or "800 Boylston Street Floor 7" or "800 Boylston Street Floor 7"
  */
  const primaryName =
    listing && listing.building
      ? listing.building.name || listing.building.address
      : ''
  return (
    primaryName +
    (listing && listing.address2
      ? `${includeComma ? ',' : ''} ${listing.address2}`
      : '')
  )
}

export function getListingSecondaryName(listing) {
  /*
  "Prudential Center Foor 7" or "Prudential Center"
  or "800 Boylston Street Suite 200" or "800 Boylston Street"
  */
  if (listing.building.name) {
    return (
      listing.building.address +
      (listing.building.address2 ? ` ${listing.building.address2}` : '')
    )
  }
  return null
}

export function formatSize(size) {
  if (size !== null && size !== undefined && size !== '') {
    const num = numberWithCommas(size)
    if (num !== null && num !== undefined && num !== '') {
      return `${num} sqft`
    }
  }
  return ''
}

export function formatSizeByListing(listing) {
  return formatSize(listing.size)
}

export function formatSqftPrice(sqftPrice) {
  return formatMoney(sqftPrice)
}

export function formatSqftPriceByListing(listing) {
  return formatSqftPrice(listing.sqft_price)
}

export function getSpaceName(address2, size, sqftPrice) {
  let address = ''
  if (address2) {
    address = address2
  } else if (size && sqftPrice) {
    address = `${formatSize(size)} - ${formatSqftPrice(sqftPrice)}`
  } else if (size) {
    address = formatSize(size)
  } else if (sqftPrice) {
    address = formatSqftPrice(sqftPrice)
  }
  return address
}

export function getSpaceNameByListing(listing) {
  return getSpaceName(listing.address2, listing.size, listing.sqft_price)
}

export function getFullAddress(listing) {
  let address = ''

  if (listing) {
    if (typeof listing === 'string') {
      address = listing
    }

    if (typeof listing === 'object') {
      address = listing.building.address || ''
      if (listing.building.city) {
        address = address
          ? `${address}, ${listing.building.city}`
          : `${listing.building.city}`
      }

      if (listing.building.state) {
        address = address
          ? `${address}, ${listing.building.state}`
          : `${listing.building.state}`
      }

      if (listing.building.zipcode) {
        address = address
          ? `${address} ${listing.building.zipcode}`
          : `${listing.building.zipcode}`
      }
    }
  }

  return address
}

export const sortAndFilterListings = (list, { order, filters }) => {
  if (!Array.isArray(list)) {
    return []
  }
  const newArr = list
    .filter((item) => {
      let shouldReturn = false
      if (filters.drafts) {
        shouldReturn =
          shouldReturn || (!item.declined && !item.shared && !item.toured)
      }
      if (filters.shared) {
        shouldReturn = shouldReturn || item.shared
      }
      if (filters.declined) {
        shouldReturn = shouldReturn || item.declined
      }
      if (filters.toured) {
        shouldReturn = shouldReturn || item.toured
      }
      return shouldReturn
    })
    .sort((a, b) => {
      if (order === 'sqft_price') return a.sqft_price - b.sqft_price
      if (order === 'size') return a.size - b.size
      if (order === '-created_at')
        return Date.parse(a.created_at) - Date.parse(b.created_at)
      return 0
    })
  return newArr
}

export const formatDateAvailableDate = (date) => {
  if (!isValid(date)) {
    return ''
  }

  return format(date, 'MMM d, y')
}

export const formatDateAvailableString = (dateString) => {
  const date = dateString ? parse(dateString, 'yyyy-MM-dd', new Date()) : null

  return date ? formatDateAvailableDate(date) : ''
}

export const formatDateAvailable = (listing) => {
  formatDateAvailableString(listing.date_available)
}

export const formatVacancyTypeByListing = (listing) => {
  return listing.vacancy_type || null
}

export const formatLeaseTypeByListing = (listing) => {
  return listing.lease_type || null
}

export const formatLeaseTermByListing = (listing) => {
  return listing.lease_term
}

export const formatBuildingPropertyTypeByListing = (listing) => {
  return listing.building.property_type || null
}

export function formatBuildingSizeByListing(listing) {
  return listing.building ? formatSize(listing.building.building_size) : null
}

export function formatBuildingFloorsByListing(listing) {
  return listing.building ? listing.building.floors : null
}

export function formatBuildingParkingRatioByListing(listing) {
  return listing.building
    ? numberWithCommas(listing.building.parking_ratio)
    : null
}

export function formatBuildingAmenitiesByListing(listing) {
  return listing.building && listing.building.amenities
    ? listing.building.amenities.join(', ')
    : null
}

export const LISTING_READ_ONLY_FIELDS_TYPE = 1
export const BUILDING_READ_ONLY_FIELDS_TYPE = 2

export const LISTING_READ_ONLY_FIELDS = [
  {
    label: 'Vacancy Type',
    getValue: formatVacancyTypeByListing,
    icon: 'sign',
    type: LISTING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Sqft Available',
    getValue: formatSizeByListing,
    icon: 'ruler-combined',
    type: LISTING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Price / Sqft',
    getValue: formatSqftPriceByListing,
    icon: 'usd-circle',
    type: LISTING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Date Available',
    getValue: formatDateAvailable,
    icon: 'calendar-day',
    type: LISTING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Lease Type',
    getValue: formatLeaseTypeByListing,
    icon: 'file-alt',
    type: LISTING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Lease Term',
    getValue: formatLeaseTermByListing,
    icon: 'file-contract',
    type: LISTING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Property Type',
    getValue: formatBuildingPropertyTypeByListing,
    icon: 'city',
    type: BUILDING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Building Size',
    getValue: formatBuildingSizeByListing,
    icon: 'drafting-compass',
    type: BUILDING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Floors',
    getValue: formatBuildingFloorsByListing,
    icon: 'stream',
    type: BUILDING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Parking Ratio',
    getValue: formatBuildingParkingRatioByListing,
    icon: 'parking',
    type: BUILDING_READ_ONLY_FIELDS_TYPE,
  },
  {
    label: 'Amenities',
    getValue: formatBuildingAmenitiesByListing,
    iconSvg: AmenitiesIcon,
    type: BUILDING_READ_ONLY_FIELDS_TYPE,
  },
].map((field) => ({ ...field, hidden: false }))

const formatListingKeysForDisplay = (
  listing,
  includeDescription = true,
  sortedCustomFieldKeys = [],
  useOldNames = true
) => {
  const fields = {}
  if (!useOldNames && includeDescription) {
    fields['Space Notes'] = listing.description || false
  }
  fields['Vacancy Type'] = listing.vacancy_type || false
  fields['SqFt Available'] =
    listing.size || listing.size === 0 ? listing.size : null
  fields['Price / SqFt'] =
    listing.sqft_price || listing.sqft_price === 0 ? listing.sqft_price : null
  fields['Date Available'] = listing.date_available || false
  fields['Lease Type'] = listing.lease_type || false
  fields['Lease Term'] = listing.lease_term || false
  if (useOldNames && includeDescription) {
    fields['Listing Description'] = listing.description || false
  }

  if (listing.custom_fields && listing.custom_fields.length) {
    let customFields = listing.custom_fields
    if (sortedCustomFieldKeys) {
      customFields = sortBy(listing.custom_fields, (cf) =>
        sortedCustomFieldKeys.indexOf(cf.name)
      )
    }
    merge(
      fields,
      customFields
        .map((customField) => ({
          [customField.name]: customField.custom_field.value,
        }))
        .reduce(merge)
    )
  }
  return fields
}

// Create a model field.
const _makeField = (id, name, value, formattedValue, reserved, dataType) => ({
  id,
  reserved,
  name,
  value,
  formattedValue,
  dataType,
})

export const formatListingForDisplay = ({
  listing,
  includeDescription = true,
  formatValues = true,
  sortedCustomFieldKeys = [],
  useOldNames = true,
}) => {
  const formatted = formatListingKeysForDisplay(
    listing,
    includeDescription,
    sortedCustomFieldKeys,
    useOldNames
  )
  if (formatValues) {
    formatted['SqFt Available'] =
      listing.size || listing.size === 0
        ? numberWithCommas(listing.size)
        : false
    formatted['Price / SqFt'] =
      listing.sqft_price || listing.sqft_price === 0
        ? formatMoney(listing.sqft_price)
        : false
    formatted['Date Available'] = formatDateAvailable(listing)
  }

  return formatted
}

export const formatListingForDisplayProper = ({
  space,
  requiredCustomFields = [],
}) => {
  // reserved fields
  const data = BULK_IMPORT_CONSTANTS.SPACE_FIELDS.filter(
    (fieldData) =>
      ![
        BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NOTES.fieldId,
        BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NAME.fieldId,
      ].includes(fieldData.fieldId)
  ).map((fieldData) => {
    const value = space[fieldData.modelFieldName]
    return _makeField(
      fieldData.fieldId,
      fieldData.displayName,
      value,
      fieldData.fieldDataType.formatter(value),
      true,
      fieldData.fieldDataType
    )
  })

  // custom fields
  if (space.custom_fields && space.custom_fields.length) {
    const customFields = space.custom_fields
    const existingCustomFieldKeys = []
    customFields.forEach((customField) => {
      const dataType =
        BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[
          customField.custom_field.data_type
        ]
      existingCustomFieldKeys.push(customField.name)
      data.push(
        _makeField(
          customField.id,
          customField.name,
          customField.custom_field.value,
          dataType.formatter(customField.custom_field.value),
          false,
          dataType
        )
      )
    })
    // For all of the missing required custom fields, add them in with value of false
    const missingFields = requiredCustomFields.filter(
      (cf) => !existingCustomFieldKeys.includes(cf.name)
    )
    if (missingFields && missingFields.length) {
      missingFields.forEach((mf) => {
        data.push(
          _makeField(undefined, mf.name, null, null, false, mf.dataType)
        )
      })
    }
  }

  return data
}

export const formatBuildingKeysForDisplay = (
  building,
  includeAmenities = true,
  includeDescription = true
) => {
  const fields = {
    'Property Type': building.property_type ? building.property_type : false,
    Floors: building.floors ? building.floors : false,
    'Building Size':
      building.building_size || building.building_size === 0
        ? building.building_size
        : false,
    'Parking Ratio': building.parking_ratio ? building.parking_ratio : false,
  }
  if (includeDescription) {
    fields['Building Description'] = building.description
      ? building.description
      : false
  }
  if (includeAmenities) {
    fields.Amenities =
      building.amenities && building.amenities.length
        ? building.amenities
        : false
  }
  if (building.custom_fields && building.custom_fields.length) {
    merge(
      fields,
      building.custom_fields
        .map((customField) => ({
          [customField.name]: customField.custom_field.value,
        }))
        .reduce(merge)
    )
  }
  return fields
}

export const formatBuildingForDisplay = (
  building,
  includeAmenities = true,
  includeDescription = true,
  formatValues = true
) => {
  const formatted = formatBuildingKeysForDisplay(
    building,
    includeAmenities,
    includeDescription
  )
  if (formatValues) {
    formatted['Building Size'] = building.building_size
      ? getNumberValueForDisplay(
          building.building_size,
          BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id
        )
      : false
  }

  return formatted
}

export const formatBuildingForDisplayProper = (building) => {
  // reserved fields
  const fields = BULK_IMPORT_CONSTANTS.BUILDING_FIELDS.filter(
    (fieldData) =>
      ![
        BULK_IMPORT_CONSTANTS.FIELDS.BUILDING_DESCRIPTION.fieldId,
        BULK_IMPORT_CONSTANTS.FIELDS.AMENITIES.fieldId,
        BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.fieldId,
      ].includes(fieldData.fieldId)
  ).map((fieldData) => {
    const value = building[fieldData.modelFieldName]
    return _makeField(
      fieldData.fieldId,
      fieldData.displayName,
      value,
      fieldData.fieldDataType.formatter(value),
      true,
      fieldData.fieldDataType
    )
  })

  // custom fields
  if (building.custom_fields && building.custom_fields.length) {
    const customFields = building.custom_fields
    customFields.forEach((customField) => {
      const dataType =
        BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[
          customField.custom_field.data_type
        ]
      fields.push(
        _makeField(
          customField.id,
          customField.name,
          customField.custom_field.value,
          dataType.formatter(customField.custom_field.value),
          false,
          BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[
            customField.custom_field.data_type
          ]
        )
      )
    })
  }

  return fields
}

export const formatCustomFieldsForDisplayProperties = (customFieldValues) => {
  return customFieldValues.map((customFieldValue) => {
    const customField = customFieldValue.custom_field
    const dataType =
      BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[
        customFieldValue.custom_field.data_type ??
          BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id // Make sure we have a data type when we first create a custom field
      ]

    return {
      id: customFieldValue.id,
      name: customField.label,
      value: customFieldValue.value,
      formattedValue: dataType.formatter(customFieldValue.value),
      reserved: false,
      dataType,
      original: customFieldValue,
    }
  })
}

export const formatListingAndBuildingMetadataForDisplayProper = (
  listing = {}
) => {
  const building = listing.building || {}
  const listingFields = formatListingForDisplayProper({ space: listing })
  const buildingFields = formatBuildingForDisplayProper(building)
  return {
    building: buildingFields,
    listing: listingFields,
  }
}

export const formatListingAndBuildingMetadataForDisplay = (listing = {}) => {
  const building = listing.building || {}
  const listingFields = formatListingForDisplay({ listing })
  const buildingFields = formatBuildingForDisplay(building)
  return {
    ...buildingFields,
    ...listingFields,
  }
}

// Floorplan or listing images
export const getListingCompareImagesForDisplay = (listing) => {
  const images = []

  const floorplanPhoto =
    listing && listing.floorplan_photos && listing.floorplan_photos.length
      ? listing.floorplan_photos[0]
      : null

  if (floorplanPhoto) {
    images.push(floorplanPhoto)
  }
  if (listing && listing.images && listing.images.length) {
    images.concat(listing.images)
  }

  return images.slice(0, 5)
}

export const getListingImagesForDisplay = (listing) => {
  let photosList = listing.images.slice(0, 5)

  const hasBuilding = listing && listing.building
  const hasBuildingPhotos =
    hasBuilding &&
    listing.building.images &&
    listing.building.images.length &&
    listing.building.images[0]
  const hasFloorplanPhotos =
    listing &&
    listing.floorplan_photos &&
    listing.floorplan_photos.length &&
    listing.floorplan_photos[0]
  const hasNeighborhoodPhotos =
    hasBuilding &&
    listing.building.neighborhood_photos &&
    listing.building.neighborhood_photos.length &&
    listing.building.neighborhood_photos[0]

  // prepend the first building image if its there
  if (hasBuildingPhotos) {
    photosList.unshift(listing.building.images[0])
  }

  // splice in the first floorplan images at the end if its there
  if (hasFloorplanPhotos) {
    photosList.splice(4, 0, listing.floorplan_photos[0])
  }

  // tack on the rest of the images in case we need to fill this out
  photosList = photosList.concat(
    hasFloorplanPhotos ? listing.floorplan_photos.slice(1) : [],
    hasBuildingPhotos ? listing.building.images.slice(1) : [],
    hasNeighborhoodPhotos ? listing.building.neighborhood_photos : []
  )

  return photosList.slice(0, 5)
}

export const getSpacesCustomFieldOrder = (spaces) => {
  const customFieldsToDateCreatedMap = {}
  spaces.forEach((space) => {
    space.custom_fields.forEach((customField) => {
      const createdAt = new Date(customField.created_at)
      if (
        !customFieldsToDateCreatedMap[customField.name] ||
        createdAt < customFieldsToDateCreatedMap[customField.name]
      ) {
        customFieldsToDateCreatedMap[customField.name] = createdAt
      }
    })
  })

  return Object.keys(customFieldsToDateCreatedMap).sort((a, b) => {
    const date1 = customFieldsToDateCreatedMap[a]
    const date2 = customFieldsToDateCreatedMap[b]
    if (date1 < date2) return -1
    if (date1 > date2) return 1
    return 0
  })
}

export const getBuildlingImagesForDisplay = (building) => {
  let photosList =
    building && building.images ? building.images.slice(0, 5) : []

  const hasNeighborhoodPhotos =
    building &&
    building.neighborhood_photos &&
    building.neighborhood_photos.length &&
    building.neighborhood_photos[0]

  // tack on the rest of the images in case we need to fill this out
  photosList = photosList.concat(
    hasNeighborhoodPhotos ? building.neighborhood_photos : []
  )

  return photosList.slice(0, 5)
}
