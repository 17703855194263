import React from 'react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import BuildingDetailPage from './BuildingDetailPage'
import BuildingDetailPageNew from './BuildingDetailPageNew'
import { FEATURE_FLAGS } from '~/legacy/utils/featureFlags'
import { useSurveySelector } from '~/legacy/utils'

export default function BuildingDetailPageWrapper({ match }) {
  const flagEnabled = useFeatureFlagEnabled(FEATURE_FLAGS.USE_NEW_CUSTOM_FIELDS)
  const { survey } = useSurveySelector(match.params.id, 'bdp', false)
  const optedInSurvey = survey?.opted_in_new_custom_fields

  console.log(
    `${FEATURE_FLAGS.USE_NEW_CUSTOM_FIELDS} flag enabled:`,
    flagEnabled
  )
  console.log('survey opted_in_new_custom_fields:', optedInSurvey)

  return flagEnabled && optedInSurvey ? (
    <BuildingDetailPageNew match={match} />
  ) : (
    <BuildingDetailPage match={match} />
  )
}
