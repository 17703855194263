import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import { Typography } from '@material-ui/core'
import { withStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import omit from 'lodash/omit'
import groupBy from 'lodash/groupBy'
import { format } from 'date-fns'
import {
  SurveyListingMenu,
  SurveyBuildingMenu,
  File,
  ExpandLessIcon,
  ExpandMoreIcon,
  MoreIcon,
  SquareIconButton,
  SquareCheckbox,
  DragIcon,
  InlineEditableValue,
  ScheduleTourIconButton,
  NotInterestedIconButton,
  LinkIconSvg,
  NoFileWithUploader,
  FavoriteBuildingButton,
  NotInterestedBuildingButton,
  updateSurveyBuildingInterestSdp,
  CompareSpacesButton,
} from '~/legacy/components'
import {
  setSurveyListing,
  setSurveyBuildings,
  setTableRowClicksEnabled,
} from '~/legacy/store/actions/viewSurvey'

import {
  formatDateAvailableDate,
  getSpaceNameByListing,
  formatSizeByListing,
  getBuildingTertiaryName,
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_HELPERS,
  getPlural,
} from '~/legacy/utils'
import { BUILDING_INTEREST } from '~/legacy/consts'

import { SURVEY_LISTING_TABLE_STYLES } from './SurveyListingTableComponents'
import { useSurveyListingCustomFieldSelector } from '~/legacy/utils/hooks/useSurveyListingCustomFieldSelector'
import { useSurveyCustomFieldsSelector } from '~/legacy/utils/hooks/useSurveyCustomFieldsSelector'

function LeftDataDiv({ isDragging, isBdpBuildout = false, ...props }) {
  const { classes, children, isBroker, inlineEditableProps = {} } = props
  const dispatch = useDispatch()

  const editable = inlineEditableProps.isEditable && isBroker

  return (
    <StyledDraggableDiv
      align="left"
      alignment="left"
      isDragging={isDragging}
      className={classes.otherWidth}
      {...omit(props, [
        'classes',
        'inlineEditableProps',
        'fieldId',
        'fieldValueId',
      ])}
      data-field-id={props.fieldId}
      data-field-value-id={props.fieldValueId}
    >
      {!!editable && (
        <InlineEditableValue
          classesIn={{
            value: classes.editableValue,
            valueInput: classes.editableValueInput,
          }}
          onClick={() => {
            if (inlineEditableProps.type !== 'date') {
              dispatch(setTableRowClicksEnabled(false))
            }
          }}
          allowParentClicks={isBdpBuildout}
          {...omit(inlineEditableProps, ['isEditable'])}
        />
      )}
      {!editable && children}
    </StyledDraggableDiv>
  )
}

const StyledLeftDataDiv = withStyles(SURVEY_LISTING_TABLE_STYLES, {
  withTheme: true,
})(LeftDataDiv)

const DraggableDiv = ({
  isDragging,
  className = '',
  alignment = '', // left, center
  classes = {},
  // eslint-disable-next-line
  isCondensed = false,
  // eslint-disable-next-line
  isBroker = false,
  ...props
}) => {
  const { style, children } = props
  // eslint-disable-next-line
  const { theme, inlineEditableProps, ...filteredProps } = props
  // Wrap the "cell" with a reference to it's original element so we can preserve
  //   the width when being dragged
  const ref = useRef(null)
  let newStyle = style || {}
  if (isDragging && ref.current) {
    newStyle = { ...newStyle, width: ref.current.offsetWidth }
  }

  let newClassName = className
  if (alignment === 'left') {
    newClassName += ` ${classes.tableDataLeft}`
  } else if (alignment === 'center') {
    newClassName += ` ${classes.centerAlign}`
  }

  return (
    <div
      {...filteredProps}
      ref={ref}
      style={newStyle}
      className={`${classes.divTableData} ${newClassName}`}
    >
      {children}
    </div>
  )
}

const StyledDraggableDiv = withStyles(SURVEY_LISTING_TABLE_STYLES, {
  withTheme: true,
})(DraggableDiv)

function SurveyListingRowDataNew(props) {
  const {
    containerProps,
    surveyId,
    collapsedBuildings,
    setCollapsedBuildings,
    draggableProvided,
    isDragging,
    selectedSurveyListings,
    setSelectedSurveyListings,
    classes,
    setSurveyListingToDecline,
    building = {},
    surveyBuilding = {},
    surveyListings = [],
    isBuilding = false,
    isNested = false,
    isFirstNested = false,
    isLastNested = false,
    isCondensed = false,
    isBroker = false,
    rowClick = () => {},
    uploadBuildingHeroPhotoModalData = {},
    setUploadBuildingHeroPhotoModalData = () => {},
    isBdpBuildout = false,
  } = props
  const dispatch = useDispatch()
  const user = useSelector((s) => s.user)
  const [anchorMenuEl, setAnchorMenuEl] = useState(null)
  const allSurveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )
  const allSurveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )
  const [isHovering, setIsHovering] = useState(false)

  const { listingCustomFields } = useSurveyCustomFieldsSelector({
    surveyId,
    enabled: Boolean(surveyId),
  })

  const {
    customFieldValues,
    mutateCreateListingCustomFieldValue: addCustomFieldValue,
    mutateChangeListingCustomFieldValue: updateCustomFieldValue,
  } = useSurveyListingCustomFieldSelector({
    surveyId,
  })

  const visibleCustomFieldColumns = isCondensed ? 2 : 5
  const visibleCustomFields = listingCustomFields.slice(
    0,
    visibleCustomFieldColumns
  )
  const missingCustomFieldsCount =
    visibleCustomFieldColumns - visibleCustomFields.length
  const missingCustomFieldColums =
    missingCustomFieldsCount > 0
      ? Array(missingCustomFieldsCount)
          .fill(null)
          .map((_, index) => index)
      : []

  const valuesPerCustomField = groupBy(customFieldValues, 'custom_field.id')

  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorMenuEl(null)
  }

  const onHeroImageClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setUploadBuildingHeroPhotoModalData({ building })
  }

  // Show either the existing hero photo, or a placeholder to open a modal to upload a photo
  const getNonNestedAddressAndImage = (heroPhoto) => {
    const showStaticPhoto = !isBroker || heroPhoto
    return (
      <div
        className={classes.nonNestedRowAddressImage}
        onClick={showStaticPhoto ? () => {} : onHeroImageClick}
      >
        {showStaticPhoto ? (
          <File
            file={heroPhoto || {}}
            skipUploading
            showAsThumbnail
            useMissingBuildingImage
            missingFileStyleOverrides={{
              backgroundColor: '#E0E0E0',
              color: '#666666',
            }}
          />
        ) : (
          <NoFileWithUploader
            uploadPhotoModalOpen={
              !!(
                uploadBuildingHeroPhotoModalData &&
                uploadBuildingHeroPhotoModalData.building &&
                building.id === uploadBuildingHeroPhotoModalData.building.id
              )
            }
            hovered={
              !!(
                uploadBuildingHeroPhotoModalData &&
                uploadBuildingHeroPhotoModalData.hovered &&
                building.id ===
                  uploadBuildingHeroPhotoModalData.hovered.building.id
              )
            }
            setHovered={(isHovered) =>
              setUploadBuildingHeroPhotoModalData(
                isHovered ? { hovered: { building } } : null
              )
            }
          />
        )}
      </div>
    )
  }

  const getBuildingAddress = (buildingLocal) => {
    return (
      <div className={classes.buildingAddressContainer}>
        <Typography>{buildingLocal.address}</Typography>
        <Typography>{getBuildingTertiaryName(buildingLocal)}</Typography>
      </div>
    )
  }

  let addressData
  let sizeData
  let checked
  let onCheck
  let indeterminate
  let listingId
  let surveyListing
  let heroPhotoData
  let rowId

  const updateListingValue = (key, value, callback = () => {}) => {
    return Api.updateListing({
      id: surveyListing.listing.id,
      body: {
        listing: {
          ...surveyListing.listing,
          [key]: value,
        },
        building: surveyListing.listing.building,
      },
    }).then(() => {
      dispatch(
        setSurveyListing({
          ...surveyListing,
          listing: {
            ...surveyListing.listing,
            [key]: value,
          },
        })
      )
      callback()
    })
  }

  if (isBuilding) {
    // Building Row
    addressData = getBuildingAddress(building)
    sizeData = surveyListings.length
      ? getPlural(surveyListings.length, 'Space')
      : 'No Spaces'
    // If any of the survey listings in the building are checked
    const checkedSurveyListings = surveyListings.filter(
      (surveyListingLocal) => selectedSurveyListings[surveyListingLocal.id]
    )
    indeterminate = !!(
      checkedSurveyListings.length &&
      checkedSurveyListings.length !== surveyListings.length
    )
    checked = checkedSurveyListings.length > 0
    // Check or uncheck all survey listings
    onCheck = () => {
      const newSelectedSurveyListings = { ...selectedSurveyListings }
      surveyListings.forEach((surveyListingLocal) => {
        newSelectedSurveyListings[surveyListingLocal.id] = !checked
        setSelectedSurveyListings(newSelectedSurveyListings)
      })
    }
    heroPhotoData = getNonNestedAddressAndImage(building.hero_photo)
    rowId = `survey-building-${building.id}`
  } else {
    // Listing row - one listing in building, or nested listing in a building
    ;[surveyListing] = surveyListings
    sizeData = formatSizeByListing(surveyListing.listing)
    listingId = surveyListing.listing.id
    checked = !!selectedSurveyListings[surveyListing.id]
    onCheck = () => {
      setSelectedSurveyListings({
        ...selectedSurveyListings,
        [surveyListing.id]: !selectedSurveyListings[surveyListing.id],
      })
    }
    indeterminate = false
    rowId = `survey-listing-${surveyListing.id}`

    if (isNested) {
      const spaceName = surveyListing.listing.address2
      addressData = (
        <div className={classes.flexRowContent}>
          <div className={classes.nestedListingIndentContainer}>
            <div
              className={clsx(
                classes.nestedListingIndentTop,
                !isFirstNested ? classes.nestedLeftBorder : ''
              )}
            />
            <div className={classes.nestedListingIndentMiddle} />
            <div
              className={clsx(
                classes.nestedListingIndentBottom,
                !isLastNested ? classes.nestedLeftBorder : ''
              )}
            />
          </div>
          {isBroker && (
            <div className={classes.spaceName}>
              <InlineEditableValue
                classesIn={{
                  value: classes.editableValue,
                  valueInput: classes.editableValueInput,
                }}
                onClick={() => {
                  dispatch(setTableRowClicksEnabled(false))
                }}
                placeholder="Add Space Name"
                type="text"
                fieldDataTypeId={
                  BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id
                }
                value={spaceName}
                updateValueApi={(newValue) =>
                  updateListingValue('address2', newValue)
                }
                allowParentClicks={isBdpBuildout}
              />
            </div>
          )}
          {!isBroker && (
            <div className={classes.spaceName}>
              <Typography variant="body1">
                {getSpaceNameByListing(surveyListing.listing)}
              </Typography>
            </div>
          )}
        </div>
      )
      heroPhotoData = ''
    } else {
      addressData = getBuildingAddress(building)
      heroPhotoData = getNonNestedAddressAndImage(
        surveyListing.listing.hero_photo
      )
    }
  }

  let isFavorited = false
  let isNotInterested = false
  if (isBdpBuildout) {
    isFavorited =
      surveyBuilding && surveyBuilding.interest === BUILDING_INTEREST.FAVORITED
    isNotInterested =
      !isFavorited &&
      surveyBuilding &&
      surveyBuilding.interest === BUILDING_INTEREST.NOT_INTERESTED
  }
  const [loadingInterest, setLoadingInterest] = useState(false)
  const theme = useTheme()
  const updateInterest = async (event, newInterest) =>
    updateSurveyBuildingInterestSdp(
      event,
      newInterest,
      surveyBuilding,
      allSurveyBuildings,
      dispatch,
      setSurveyBuildings,
      setLoadingInterest,
      theme
    )

  const buildingEmptyColumns = isCondensed ? [1] : [1, 2, 3]

  return (
    <div
      id={rowId}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...containerProps}
    >
      {isBroker ? (
        <StyledDraggableDiv
          isCondensed={isCondensed}
          isBroker={isBroker}
          isDragging={isDragging}
          alignment="center"
          className={clsx(classes.dragWidth, classes.dragPadding)}
        >
          <div
            {...draggableProvided.dragHandleProps}
            className={classes.dragIcon}
          >
            <DragIcon className={classes.black} />
          </div>
        </StyledDraggableDiv>
      ) : (
        <StyledDraggableDiv
          isCondensed={isCondensed}
          isBroker={isBroker}
          isDragging={isDragging}
          alignment="center"
          className={clsx(classes.leftCheckboxSpaceWidth)}
        >
          <div
            {...draggableProvided.dragHandleProps}
            className={classes.dragIcon}
          />
        </StyledDraggableDiv>
      )}

      <StyledDraggableDiv
        isCondensed={isCondensed}
        alignment="center"
        isDragging={isDragging}
        className={classes.checkboxWidth}
      >
        {!!(
          !isBuilding ||
          (isBuilding && surveyListings && surveyListings.length)
        ) && (
          <SquareCheckbox
            checked={checked}
            disabled={user.isAnonymous}
            indeterminate={indeterminate}
            onClick={() => {
              onCheck()
            }}
            className={clsx(
              user.isAnonymous ? classes.checkboxDisabled : classes.checkbox
            )}
          />
        )}
      </StyledDraggableDiv>

      {isBuilding && surveyListings && surveyListings.length ? (
        <StyledDraggableDiv
          isCondensed={isCondensed}
          isBroker={isBroker}
          alignment="center"
          isDragging={isDragging}
          className={classes.expandWidth}
        >
          <SquareIconButton
            onClick={() => {
              setCollapsedBuildings(
                building.id,
                !collapsedBuildings[building.id]
              )
            }}
          >
            {collapsedBuildings[building.id] ? (
              <ExpandMoreIcon className={classes.black} />
            ) : (
              <ExpandLessIcon className={classes.black} />
            )}
          </SquareIconButton>
        </StyledDraggableDiv>
      ) : (
        <StyledDraggableDiv
          isCondensed={isCondensed}
          isBroker={isBroker}
          isDragging={isDragging}
          className={classes.expandWidth}
          onClick={rowClick}
        />
      )}

      <StyledDraggableDiv
        isCondensed={isCondensed}
        isBroker={isBroker}
        alignment="center"
        isDragging={isDragging}
        className={classes.heroPhotoWidth}
        onClick={rowClick}
      >
        {heroPhotoData}
      </StyledDraggableDiv>

      <StyledDraggableDiv
        isCondensed={isCondensed}
        isBroker={isBroker}
        alignment="left"
        isDragging={isDragging}
        className={classes.addressWidth}
        onClick={rowClick}
      >
        {addressData}
      </StyledDraggableDiv>

      {isBuilding && (
        <>
          <StyledLeftDataDiv
            isCondensed={isCondensed}
            isDragging={isDragging}
            isBroker={isBroker}
            onClick={rowClick}
          >
            {sizeData}
          </StyledLeftDataDiv>

          {buildingEmptyColumns.map((key) => (
            <StyledLeftDataDiv
              key={key}
              isCondensed={isCondensed}
              isDragging={isDragging}
              isBroker={isBroker}
              onClick={rowClick}
            />
          ))}
        </>
      )}

      {!isBuilding && (
        <>
          {visibleCustomFields.map((field) => {
            const listingId = surveyListing.listing.id

            const customFieldValues = valuesPerCustomField[field.id] || []
            const customFieldValue = customFieldValues.find(
              (cfv) => cfv.listing_id === listingId
            ) || { value: '' }
            const fieldValueId = customFieldValue.id

            const dataType =
              BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[field.data_type]
            const isDate = dataType.name === 'Date'

            const value = isDate
              ? BULK_IMPORT_HELPERS.prepareDate(customFieldValue.value)
              : customFieldValue.value

            const formatDisplayValue = isDate
              ? formatDateAvailableDate
              : undefined

            return (
              <StyledLeftDataDiv
                key={field.id}
                isBroker={isBroker}
                isCondensed={isCondensed}
                isDragging={isDragging}
                inlineEditableProps={{
                  isEditable: true,
                  placeholder: `Add Value`,
                  type: dataType.inputType,
                  value,
                  formatDisplayValue,
                  updateValueApi: (value) => {
                    console.log({ value })

                    const newValue = isDate
                      ? format(value, 'yyyy-MM-dd')
                      : value

                    return new Promise((resolve) => {
                      if (fieldValueId) {
                        updateCustomFieldValue({
                          id: fieldValueId,
                          newValue,
                        })
                      } else {
                        addCustomFieldValue({
                          surveyId,
                          listingId,
                          customFieldId: field.id,
                          newValue,
                        })
                      }
                      resolve(newValue)
                    })
                  },
                  fieldDataTypeId: dataType.id,
                }}
                onClick={rowClick}
                isBdpBuildout={isBdpBuildout}
                fieldId={field.id}
                fieldValueId={customFieldValue.id}
              >
                {dataType.formatter(value)}
              </StyledLeftDataDiv>
            )
          })}

          {/* FIXME: Find a better solution */}
          {missingCustomFieldColums.map((item) => (
            <StyledLeftDataDiv
              key={item}
              isCondensed={isCondensed}
              isDragging={isDragging}
              isBroker={isBroker}
              onClick={rowClick}
            />
          ))}
        </>
      )}

      {isBroker ? (
        [
          <StyledDraggableDiv
            isCondensed={isCondensed}
            isBroker={isBroker}
            alignment="center"
            isDragging={isDragging}
            className={clsx(classes.moreOptions, classes.moreOptionsWidth)}
            onClick={rowClick}
            key="more-options-container"
          >
            {isBuilding && surveyListings.length > 1 && (
              <CompareSpacesButton
                classes={{
                  iconButton: clsx(
                    classes.hoverAction,
                    !isHovering ? classes.hidden : ''
                  ),
                }}
                surveyId={surveyId}
                spaceIds={surveyListings.map((sl) => sl.listing.id)}
              />
            )}
            {!!(
              (isBuilding || (!isBuilding && isBdpBuildout)) &&
              surveyListings.length <= 1
            ) && <div style={{ width: '43px' }} />}
            {!!(!isBuilding && !isBdpBuildout) && (
              <SquareIconButton
                className={clsx(
                  classes.linkIconBroker,
                  classes.hoverAction,
                  !isHovering ? classes.hidden : ''
                )}
              >
                <LinkIconSvg onClick={rowClick} />
              </SquareIconButton>
            )}
            <div>
              <SquareIconButton
                aria-label="more options"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  return handleMenuClick(event)
                }}
              >
                <MoreIcon className={classes.black} />
              </SquareIconButton>
            </div>
          </StyledDraggableDiv>,
          !isBuilding ? (
            <SurveyListingMenu
              listingId={listingId}
              surveyId={surveyId}
              surveyListing={surveyListing}
              setSelectedSurveyListings={setSelectedSurveyListings}
              handleMenuClose={handleMenuClose}
              id="more-options-menu"
              key="more-options-menu"
              anchorEl={anchorMenuEl}
              open={!!anchorMenuEl}
              onClose={handleMenuClose}
              elevation={2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              isBdpBuildout={isBdpBuildout}
            />
          ) : (
            <SurveyBuildingMenu
              building={building}
              surveyId={surveyId}
              handleMenuClose={handleMenuClose}
              id="more-options-menu"
              key="more-options-menu"
              anchorEl={anchorMenuEl}
              open={!!anchorMenuEl}
              onClose={handleMenuClose}
              elevation={2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            />
          ),
        ]
      ) : (
        <StyledDraggableDiv
          isCondensed={isCondensed}
          isBroker={isBroker}
          alignment="center"
          isDragging={isDragging}
          className={classes.tenantActionsWidth}
          onClick={rowClick}
        >
          {!!(
            !isBuilding ||
            (isBuilding && !user.isAnonymous && isBdpBuildout)
          ) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className={classes.linkIconTenant} onClick={rowClick}>
                <LinkIconSvg
                  className={
                    !isHovering || isBuilding || isBdpBuildout
                      ? classes.hidden
                      : ''
                  }
                />
              </div>
              {!user.isAnonymous && !isBdpBuildout && (
                <>
                  <div style={{ marginRight: 'auto' }}>
                    <ScheduleTourIconButton
                      surveyListing={surveyListing}
                      allSurveyListings={allSurveyListings}
                      ScheduleTourCheckedIconProps={{
                        className: classes.black,
                      }}
                      ScheduleTourIconProps={{ className: classes.black }}
                    />
                  </div>
                  <div style={{ paddingRight: '19px', marginLeft: 'auto' }}>
                    <NotInterestedIconButton
                      surveyListing={surveyListing}
                      setSurveyListingToDecline={setSurveyListingToDecline}
                      NotInterestedIconProps={{ className: classes.black }}
                    />
                  </div>
                </>
              )}
              {isBuilding && !user.isAnonymous && isBdpBuildout && (
                <>
                  <div style={{ marginRight: 'auto' }}>
                    <FavoriteBuildingButton
                      disabled={loadingInterest}
                      TooltipProps={{ enterDelay: 2000 }}
                      isFavorited={isFavorited}
                      isNotInterested={isNotInterested}
                      key="favorite-icon"
                      onClick={(event) =>
                        updateInterest(
                          event,
                          isFavorited
                            ? BUILDING_INTEREST.NEUTRAL
                            : BUILDING_INTEREST.FAVORITED
                        )
                      }
                    />
                  </div>
                  <div style={{ paddingRight: '19px', marginLeft: 'auto' }}>
                    <NotInterestedBuildingButton
                      disabled={loadingInterest}
                      TooltipProps={{ enterDelay: 2000 }}
                      isNotInterested={isNotInterested}
                      isFavorited={isFavorited}
                      key="not-interested-icon"
                      onClick={(event) =>
                        updateInterest(
                          event,
                          isNotInterested
                            ? BUILDING_INTEREST.NEUTRAL
                            : BUILDING_INTEREST.NOT_INTERESTED
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </StyledDraggableDiv>
      )}
    </div>
  )
}

export default withStyles(SURVEY_LISTING_TABLE_STYLES, { withTheme: true })(
  SurveyListingRowDataNew
)
